<template>
   <div id="catalog">
        <!-- Products -->
        <CatalogSection/>
    </div> 
</template>

<script>
import CatalogSection from '../components/blocks/CatalogSection.vue'

export default {
    name: 'Catalog',
    components: {
        CatalogSection
    }
}
</script>